import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SharedService } from '../../shared/service/shared.service';

@Component({
  selector: 'app-add-participantes-dialog',
  templateUrl: './add-participantes-dialog.component.html',
  styleUrls: ['../project.component.scss']
})
export class AddParticipantesDialogComponent {
  selectedUserIds: number[] = [];
  projectId: any;
  ownerForm: FormGroup;
  filteredUsers: any[] = [];
  selectedUsers: any[] = [];
  filterValue: string = '';

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddParticipantesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: SharedService,
    private snackBar: MatSnackBar,
  ) {
    this.projectId = data.projectId;
    this.ownerForm = this.fb.group({
      owner: ['', Validators.required]
    });

  }

  ngOnInit(): void {
    this.filteredUsers = this.data.users;
    this.selectedUsers = this.data.users.filter(user => this.selectedUserIds.includes(user.id));

  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();

    this.filteredUsers = this.data.users.filter(user =>
      !this.selectedUserIds.includes(user.id) && user.nomeUser.toLowerCase().includes(filterValue)
    );
  }

  onSelectionChange(): void {
    const selectedIds = this.ownerForm.value.responsible;
    this.selectedUsers = this.data.users.filter(user => selectedIds.includes(user.id));

    this.filteredUsers = this.data.users.filter(user =>
      !selectedIds.includes(user.id) && user.nomeUser.toLowerCase().includes(this.filterValue)
    );
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  isSaving = false;
  onSave(): void {
    if (this.isSaving) return;
    this.isSaving = true;
    const formValues = this.ownerForm.value;


    if (this.ownerForm.valid) {
      this.service.addOwnerProject(formValues.owner, this.projectId)
        .subscribe(response => {
          console.log(response)
          if (response.status == 200) {
            this.snackBar.open('Participantes adicionados com sucesso!', 'Fechar', {
              duration: 1500,
              panelClass: ['snack-bar-success']
            });
            this.dialogRef.close();

            setTimeout(() => {
              window.location.reload();
            }, 1500);
          }else if (response.status == 400) {
            this.snackBar.open('Você não tem permissão!', 'Fechar', {
              duration: 1500,
              panelClass: ['snack-bar-success']
            });
            this.dialogRef.close();
          } else {
            this.snackBar.open('Erro Ao Adicionar Participantes', 'Fechar', {
              duration: 1500,
              panelClass: ['snack-bar-success']
            });
          }
        }, error => {
          this.snackBar.open('Erro Ao Adicionar Participantes', 'Fechar', {
            duration: 1500,
            panelClass: ['snack-bar-success']
          });

        });
    }
  }

}
