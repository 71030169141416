import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SharedService } from '../../shared/service/shared.service';
@Component({
  selector: 'app-remove-dialog',
  templateUrl: './remove-dialog.component.html',
  styleUrls: ['../operational-unit.component.scss']
})
export class RemoveDialogComponent {
  nomeCompany: any;
  constructor(
    public dialogRef: MatDialogRef<RemoveDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: SharedService,
    private snackBar: MatSnackBar
  ) {

  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  confirmRemove(): void {
    this.service.removeOperational(this.data.uuid).subscribe(
      (response: any) => {
        if (response.status == 200) {
          this.snackBar.open('Companhia Removido com sucesso!', 'Fechar', {
            duration: 1500,
            panelClass: ['snack-bar-success']
          });
          setTimeout(() => {
            window.location.reload();
          }, 1500);

          this.dialogRef.close();
        } else if (response.status == 400) {
          this.snackBar.open('Você Não tem Permissão', 'Fechar', {
            duration: 1500,
            panelClass: ['snack-bar-success']
          });
        } else {
          this.snackBar.open('Ocorreu um Erro tente Novamente mais tarde', 'Fechar', {
            duration: 1500,
            panelClass: ['snack-bar-success']
          });
        }
      }
    );
  }
}
