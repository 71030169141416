import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SharedService } from '../../shared/service/shared.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {
  path = ['Empreendimento Licentia'];
  tabs = ['Unidades'];
  operationalData = {
    name: 'Empreendimento Licentia',
    type: 'Empreendimento',
    searchTooltip: 'Pesquisar Unidades Operacionais'
  };

  contentType = 'Projeto';
  panelData = {
    description: 'Visualize aqui as principais informações do seu Emprendimento',
    indicators: ['Condicionantes por projeto e por status', 'Condicionantes por projeto e por status',
      'Condicionantes por projeto e por status', 'Condicionantes por projeto e por status']
  };

  userForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<AddUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private service: SharedService,
    private snackBar: MatSnackBar,
    private http: HttpClient,
  ) {
    this.userForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      lastName: ['', Validators.required],
      name: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]],
      phone: ['', [Validators.required, Validators.pattern(/^\d{11}$/)]], // Formato com 11 dígitos
      perfil: ['', Validators.required],
    });
  }

  ngOnInit() {
  }

  onSubmit() {
    if (this.userForm.valid) {
      this.snackBar.open('Perfil atualizado com sucesso!', 'Fechar', {
        duration: 1500,
        panelClass: ['snack-bar-success']
      });
      this.dialogRef.close();

      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } else {
      console.log('Formulário inválido');
    }
  }
}
