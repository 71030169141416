import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-constraint-list',
  templateUrl: './constraint-list.component.html',
  styleUrls: ['./constraint-list.component.sass']
})
export class ConstraintListComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
